<template>
  <div class="progress-bar">
    <div class="main-empty">
      <div class="fill" :style="'width:' + (percent > 100 ? 100 : percent) +'%'" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CProgressBar',
  props: {
    fillAt: {
      type: Number, default: 0,
    },
  },
  data() {
    return {
      percent: 0,
    }
  },
  watch: {
    fillAt() {
      this.percent = this.fillAt
    },
  },
  created() {
    this.percent = this.fillAt
  },
  mounted() {
    this.percent = this.fillAt
  },
}
</script>

<style lang="scss" scoped>
.progress-bar {
  .main-empty {
    position: relative;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #D9D9D9;

    .fill {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: var(--primary);
      height: 4px;
    }
  }
}
</style>
