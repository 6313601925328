<template>
  <div class="c-checkpoint-sheet">
    <n-loader :loading="$var('load')" />
    <div class="settings">
      <div class="date-picker">
        <div class="month">
          <div class="month-prev btn" @click="decrease"><img :src="left" alt=""></div>
          <div class="month-title">{{ months[month] }} {{ year }}</div>
          <div class="month-next btn" @click="increase"><img :src="right" alt=""></div>
        </div>
      </div>
      <div class="filters">
        <c-filter title="Филиал" return-field="id" :items="branches" :filter.sync="branch" @load="loadSheet" @clean="clean('branch')" />
        <c-filter
          title="Отдел" return-field="value"
          filter-type="radio" :items="departments" :filter.sync="department" @load="loadSheet" @clean="clean('department')" />
      </div>
    </div>
    <div class="types-wrapper">
      <div v-for="item in types" :key="item.title" class="type">
        <span class="circle" :style="'background-color:'+item.color+';'" />
        <span class="title">{{ item.title }}</span>
      </div>
    </div>
    <div class="new-table-wrap">
      <table class="new-table">
        <tr>
          <th class="cell num header" rowspan="2">№</th>
          <th class="cell fio header" rowspan="2">ФИО</th>
          <th class="cell head header" rowspan="2">Отдел</th>
          <th class="cell head header" rowspan="2">Должность</th>
          <th v-for="(day, index) in rDays" :key="index" class="cell header day">
            {{ day.index }}
          </th>
        </tr>
        <tr>
          <th v-for="(weekDay, index) in makeWeekDays()" :key="index" class="cell header low">
            {{ weekDay }}
          </th>
        </tr>
        <tr v-for="(item,index) in users" :key="item.id" class="user-row" @click="$router.push({name: 'user', params: {id: item.id}})">
          <th class="cell num user marked">{{ index+1 }}</th>
          <th class="cell fio user marked">{{ item.fullName }}</th>
          <th class="cell head user marked">{{ item.department?.title }}</th>
          <th class="cell head user marked">{{ item.position }}</th>
          <th
            v-for="(day, dindex) in rDays" :key="dindex" class="cell user"
            :style="'background-color:'+getColor(item.checkpointData? item.checkpointData[day.date]?.[0]: '', day.date)+';'">
            <div v-show="item.checkpointData[day.date]?.[0]" class="hours user">
              {{ item.checkpointData[day.date]?.[0]?.checks?.length +'/'+ item.checkpointData[day.date]?.[0]?.countToCheck }}
            </div>
          </th>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

import left from '../../assets/arrow-left.svg'
import right from '../../assets/arrow-right.svg'
export default {
  name: 'CCheckpointSheet',
  props: {
    checklistId: { required: true, },
    checkpointId: { required: true, },
  },
  data() {
    return {
      left,
      right,
      date: new Date(),
      cDate: new Date(),
      months: {},
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
      },
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
      },
      month: null,
      year: null,
      days: [],
      departments: [],
      branches: [],
      branch: [],
      department: [],
      defDays: [],
      rWeeks: [],
      rDays: [],
      users: [],
      types: [
        { title: 'Нет данных', color: '#ffffff', },
        { title: 'Отмечены все', color: '#64C038', },
        { title: 'Отмечены не все', color: '#A70F61', },
        { title: 'Не отмечено', color: '#FF0000', },
      ],
    }
  },
  created() {
    this.initSheet()
    this.loadSheet()
    this.loadBranches()
    this.loadDepartments()
  },
  methods: {
    getColor(value, date) {
      const checkCount = value?.checks.length
      if (checkCount >= value?.countToCheck) {
        return '#64C038'
      }
      if (checkCount < value?.countToCheck && checkCount > 0) {
        return '#A70F61'
      }

      if (checkCount <= 0) {
        return '#FF0000'
      }
      return '#ffffff'
    },
    updateView() {
      this.dateForView = (this.cDate.getDate() <= 9 ? '0' + this.cDate.getDate() : this.cDate.getDate()) +
          ' ' + this.months[this.month].substr(0, 3) + '. ' + this.year
    },
    initSheet() {
      const lang = $app.store.getter('app.lang') || 'ru'
      this.months = this.localeMonths[lang]
      this.defDays = this.localeDay[lang]
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
      this.calendar()
    },
    makeWeekDays() {
      const weekDays = []
      let i = 0
      $n.each(this.rDays, (e) => {
        weekDays.push(this.days[i])
        i++
        if (i > 6) {
          i = 0
        }
      })
      return weekDays
    },
    calendar: function() {
      let days = {}
      const dlast = new Date(this.year, this.month + 1, 0).getDate()

      for (let i = 1; i <= dlast; i++) {
        days = {
          ...days,
          [Number(this.getNumberOfDay(new Date(this.year, this.month, i)))]: {
            index: i,
            dayNum: new Date(this.year, this.month, i).getDay(),
            date: this.$app.date.format(new Date(this.year, this.month, i), 'date', true),
          },
        }
      }
      this.rDays = days
      const key = Object.keys(days)[0]
      const fDay = days[key]
      const rDay = []
      const defDays = [ ...this.defDays, ]
      rDay.push(...defDays.splice(fDay.dayNum === 0 ? 6 : fDay.dayNum - 1))
      rDay.push(...defDays.splice(0, fDay.dayNum === 0 ? 6 : fDay.dayNum - 1))
      this.days = rDay
    },
    getNumberOfDay(today) {
      const startOfYear = new Date(today.getFullYear(), 0, 0)
      const diff = today - startOfYear

      const oneDay = 1000 * 60 * 60 * 24
      return Math.floor(diff / oneDay)
    },
    decrease() {
      this.month--
      if (this.month < 0) {
        this.month = 12
        this.month--
        this.year--
      }
      this.calendar()
      this.loadSheet()
    },
    increase() {
      this.month++
      if (this.month > 11) {
        this.month = -1
        this.month++
        this.year++
      }
      this.calendar()
      this.loadSheet()
    },
    loadBranches() {
      this.$var('load', true)
      $api.branches.get($app.auth.user().companyId).then((response) => {
        this.branches = []
        $n.each(response.data.content, (e) => {
          this.branches.push({ value: e.id, title: e.title, id: e.id, })
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadDepartments() {
      this.$var('load', true)
      $api.departments.get($app.auth.user().companyId).then((response) => {
        this.departments = []
        $n.each(response.data.content, (e) => {
          this.departments.push({ value: e.id, title: e.title, })
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadSheet() {
      this.$var('load', true)
      let query = 'month='+(this.month+1)+'&year='+this.year
      const api = $api.checkpoints.getSheet(this.checklistId, this.checkpointId)
        .with('branches').with('department')

      if (this.branch.length !== 0) {
        const strFilt = $n.branchesToStr(this.branch)
        query = query+'&branchId='+strFilt
      }
      if (this.department) {
        query = query+'&department='+this.department
      }
      api.query(query).then((response) => {
        this.users = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-checkpoint-sheet {
  position: relative;

  .month {
    margin-bottom: 20px;
    background: var(--primary);
    border-radius: 8px;
    width: 200px;
    height: 45px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #FFF;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .my-date-picker {
    --v-calendar-text-color: #000000;
    --v-calendar-datepicker-icon-color: var(--primary);
    --v-calendar-input-border: 1px solid var(--primary);
    --v-calendar-input-text-color: #000000;

    ::v-deep input {
      height: 40px;
      border-radius: 24px !important;
      color: var(--primary) !important;
      font-weight: 700;
      border: 3px solid var(--primary);
    }
  }
  .settings {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filters {
      display: flex;
      align-items: center;
    }
  }
  .types-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .type {
      margin-top: 5px;
      display: flex;
      align-items: center;
      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }

      .circle {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        width: 14px;
        height: 14px;
        background-color: #64C038;
        margin-right: 5px;
      }

      .title {
        font-weight: 400;
        height: 100%;
        line-height: 1;
        color: #000000;
        font-size: 14px;
      }
    }
  }

  .hours {
    font-size: 11.5px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timesheet-table-wrap {
    display: flex;
    border: 1px solid var(--primary);
    border-right: none;
    margin-top: 44px;

    .left {
      width: 460px;
      border-right: 1px solid var(--primary);

      .row {
        border-bottom: 1px solid var(--primary);

        &:last-child {
          border-bottom: none;
        }

        & > div {
          border-right: 1px solid var(--primary);
          font-size: 11px;
          font-weight: 500;
          padding: 0 5px;
          line-height: 13px;
          text-align: center;

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    .right {
      &::-webkit-scrollbar {
        width: 0; /* убираем ширину вертикального скролла */
        height: 0; /* убираем высоту горизонтального скролла */
      }

      flex: 3;
      overflow: auto;
      max-width: calc(100vw - 460px);
      width: 100%;

      .row {
        justify-content: space-between;

        &:last-child {
          border-bottom: none;

          & > div {
            border-bottom: none;
          }
        }

        & > div {
          border-right: 1px solid var(--primary);
          display: flex;
          flex: 1;
          width: auto !important;
          border-bottom: 1px solid var(--primary);
          height: 42.8px !important;

          & > div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:last-child {
            //border-right: none;
          }
        }
      }
    }

    .t-head-title {
      display: flex;
      border-bottom: 1px solid var(--primary);
      height: 70px;

      & > div {
        width: calc(100% / 3);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        border-right: 1px solid var(--primary);

        &:last-child {
          border-right: none;
        }
      }
    }

    .t-head {
      .num {
        height: 35px;
        display: flex;
        justify-content: space-between;

        & > div {
          display: flex;
          flex: 1;

          & > div {
            border-bottom: 1px solid var(--primary);
            border-right: 1px solid var(--primary);
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
          }

          &:last-child {
            & > div {
              //border-right: none;
            }
          }
        }
      }

      .day {
        height: 35px;
        display: flex;
        justify-content: space-between;

        & > div {
          display: flex;
          flex: 1;

          & > div {
            border-bottom: 1px solid var(--primary);
            border-right: 1px solid var(--primary);
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
          }

          &:last-child {
            & > div {
              //border-right: none;
            }
          }
        }
      }
    }

    .t-body {

      .row {
        display: flex;

        & > div {
          width: calc(100% / 3);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 42px;
        }
      }
    }
  }
  .new-table-wrap {
    position: relative;
    margin-top: 30px;
    .new-table {
      width: 100%;
      margin: 0 !important;
      padding: 0;
      table-layout: fixed;
      .user-row {
        &:hover {
          .marked {
            background-color: #EBEBEB;
          }
        }
      }
      .cell {
        width: 100%;
        padding: 5px;
        height: 35px;
        font-weight: 500;
        font-size: 12px;
        &.header {
          position: sticky;
          top: 107px;
          z-index: 10000;
          background-color: #EBEBEB;
          &.low {
            top: 142px;
          }
        }

        &.fio {
          width: 150px;
        }
        &.head {
          width: 120px;
        }
        &.num {
        }
        &.user {
          height: 44px;
          font-size: 13px;
          font-weight: 500;
          line-height: 1;

          border-bottom: 2px solid #EBEBEB;
          border-left: 2px solid #EBEBEB;
          border-right: 2px solid #EBEBEB;
        }
      }
    }
  }
}
</style>
