<template>
  <div class="c-my-calendar">
    <div v-if="isOpen" class="invisible-close" @click="isOpen = !isOpen" />
    <div class="date-place" @click="isOpen = !isOpen">
      <span>{{ dateForView }}</span>
    </div>

    <div v-if="isOpen" class="calendar">
      <div class="top">
        <div class="dec" @click="decrease">&#9660;</div>
        <div class="date"> {{ months[month] }} {{ year }} </div>
        <div class="inc" @click="increase">&#9660;</div>
      </div>
      <div class="header">
        <table>
          <tr>
            <th v-for="(d, index) in day" :key="index" class="week-day">{{ d }}</th>
          </tr>
        </table>
      </div>
      <div class="table-wrapper">
        <table class="calendar-table">
          <tr v-for="(week, index) in calendar()" :key="index" class="week">
            <td @click="chooseDay(week[1]?.index)">
              <div class="number" :class="{'active': compareDate(week[1]?.index)}">
                {{ week[1]?.index }}
              </div>
            </td>
            <td @click="chooseDay(week[2]?.index)">
              <div class="number" :class="{'active': compareDate(week[2]?.index)}">
                {{ week[2]?.index }}
              </div>
            </td>
            <td @click="chooseDay(week[3]?.index)">
              <div class="number" :class="{'active': compareDate(week[3]?.index)}">
                {{ week[3]?.index }}
              </div>
            </td>
            <td @click="chooseDay(week[4]?.index)">
              <div class="number" :class="{'active': compareDate(week[4]?.index)}">
                {{ week[4]?.index }}
              </div>
            </td>
            <td @click="chooseDay(week[5]?.index)">
              <div class="number" :class="{'active': compareDate(week[5]?.index)}">
                {{ week[5]?.index }}
              </div>
            </td>
            <td @click="chooseDay(week[6]?.index)">
              <div class="number" :class="{'active': compareDate(week[6]?.index)}">
                {{ week[6]?.index }}
              </div>
            </td>
            <td @click="chooseDay(week[0]?.index)">
              <div class="number" :class="{'active': compareDate(week[0]?.index)}">
                {{ week[0]?.index }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CMyCalendar',
  props: {
    date: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
      },
      dateForView: '',
      day: [],
      cDate: new Date,
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
      },
      months: [],
      dlast: new Date(this.year, this.month + 1, 0).getDate(),
      isOpen: false,
    }
  },
  watch: {
    cDate() {
      this.updateView()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const lang = $app.store.getter('app.lang') || 'ru'
      this.months = this.localeMonths[lang]
      this.day = this.localeDay[lang]
      this.cDate = this.date
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
      this.updateView()
    },
    updateView() {
      this.dateForView = (this.cDate.getDate() <= 9 ? '0'+this.cDate.getDate(): this.cDate.getDate()) +
          ' '+ this.months[this.month].substr(0, 3) +'. '+ this.year
    },
    chooseDay(day) {
      if (day) {
        this.cDate = new Date(this.year, this.month, day)
        this.$emit('update:value', new Date(this.year, this.month, day))
        this.$emit('change')
        this.isOpen = false
      }
    },
    compareDate(day, sign = '=') {
      if (sign === '=') {
        return $app.date.format(this.cDate, 'date', true) === $app.date.format(new Date(this.year, this.month, day), 'date', true)
      } else {
        if (sign === '>=') {
          return $app.date.format(this.cDate, 'date', true) >= $app.date.format(new Date(this.year, this.month, day), 'date', true)
        } else {
          return $app.date.format(this.cDate, 'date', true) <= $app.date.format(new Date(this.year, this.month, day), 'date', true)
        }
      }
    },
    calendar: function() {
      let weeks = {}
      let week = 0
      let days = {}
      const dlast = new Date(this.year, this.month + 1, 0).getDate()

      for (let i = 1; i <= dlast; i++) {
        days = {
          ...days,
          [new Date(this.year, this.month, i).getDay()]: { index: i, },
        }
        if (new Date(this.year, this.month, i).getDay() === 0) {
          weeks = {
            ...weeks,
            [week]: days,
          }
          week+=1
          days = {}
        }
      }
      weeks = {
        ...weeks,
        [week]: days,
      }
      return weeks
    },
    decrease() {
      this.month--
      if (this.month < 0) {
        this.month = 12
        this.month--
        this.year--
      }
    },
    increase() {
      this.month++
      if (this.month > 11) {
        this.month = -1
        this.month++
        this.year++
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-my-calendar {
  margin-top: 15px;
  .invisible-close {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .date-place {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    height: 50px;
    font-size: 0.9rem;
    color: black;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 0 10px;
  }
  .calendar {
    min-height: 360px;
    box-shadow: -9.526px 5.5px 16px rgb(0 0 0 / 45%);
    border-radius: 20px 20px 0 0;
    background-color: #EEEEEE;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    .header {
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      th {
        padding: 10px;
        width: 50px;
        height: 22px;
        color: #7C8187;
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px 20px 0 0;
      padding: 20px 25px;
      background-color: #FFFFFF;
      .dec {
        color: #7C8187;
        font-size: 18px;
        transform: rotate(90deg);
      }
      .inc {
        color: #7C8187;
        font-size: 18px;
        transform: rotate(-90deg);
      }
      .date {
        color: #7C8187;
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
    .table-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .calendar-table {
      border-collapse: collapse;

      td {
        padding: 5px;
        width: 50px;
        height: 22px;
        .number {
          text-align: center;
          &.active {
            border-radius: 8px;
            color: #FFFFFF;
            background-image: linear-gradient(232deg, #0b85d7 0%, #2bbbdb 100%, #2bbbdb 100%);;
          }
        }
      }
    }
  }
}
</style>
