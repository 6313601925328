<template>
  <div class="c-pagination">
    <div class="button" @click="next(page-1)">{{ '<' }}</div>
    <div class="button middle"><input :value="page" class="mini-input" @input="inputValue">/{{ pages }}</div>
    <div class="button" @click="next(page+1)">{{ '>' }}</div>
  </div>
</template>
<script>

export default {
  name: 'CQr',
  props: {
    pages: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    inputValue(e) {
      const page = e.srcElement.value
      if (page <= this.pages && page > 0) {
        this.$emit('update:page', page)
        this.$emit('change')
      }

      if (page > this.pages) {
        e.srcElement.value = this.pages
        this.$emit('update:page', this.pages)
        this.$emit('change')
      }
    },
    next(page) {
      if (page <= this.pages && page > 0) {
        this.$emit('update:page', page)
        this.$emit('change')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .c-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    .mini-input {
      min-width: 10px;
      max-width: 30px;
      text-align: center;
      margin-right: 2px;
      height: 20px;
    }
    .button {
      cursor: pointer;
    }
    .middle {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
</style>
