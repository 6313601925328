/**
 * 
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
    info: () => $app.api.get([ 'auth/info', ]),
  }

  files = {
    create: (data) => {
      const formData = new FormData()
      $n.each(data, (value, name) => {
        formData.append(name, value)
      })
      return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
    },
  }
  
  accesses = {
    getPermits: () => $app.api.get([ 'auth/permits', ]),
    getRoles: () => $app.api.get([ 'auth/roles', ]),
    deleteRole: (id) => $app.api.delete([ 'auth/roles*', id, ]),
    createRole: (data) => $app.api.post([ 'auth/roles', ]).data(data),
    detachAccess: (id) => $app.api.delete([ 'auth/accesses*', id, ]),
    createAccess: (data) => $app.api.post([ 'auth/accesses', ]).data(data),
  }

  locationPoints = {
    get: (companyId, id) => $app.api.get([ 'companies*/location-points*', companyId, id, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/location-points', companyId, ]).data(data),
    edit: (id, data) => $app.api.patch([ 'location-points*', id, ]).data(data),
    getPattern: (companyId, id) => $app.api.get([ 'companies*/location-points*/download-pattern', companyId, id, ]),
    delete: (id) => $app.api.delete([ 'location-points*', id, ]),
  }
  
  positions = {
    get: (companyId, id) => $app.api.get([ 'companies*/positions*', companyId, id, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/positions', companyId, ]).data(data),
    remove: (companyId, id) => $app.api.delete([ 'companies*/positions*', companyId, id, ]),
    edit: (companyId, id, data) => $app.api.patch([ 'companies*/positions*', companyId, id, ]).data(data),
  }

  companies = {
    get: (id) => $app.api.get([ 'companies*', id, ]),
    edit: (id, data) => $app.api.patch([ 'companies*', id, ]).data(data),
    loadExcel: (id, data) => $app.api.post([ 'companies*/make-sheet', id, ]).data(data),
    loadStats: (id, data) => $app.api.post([ 'companies*/excel-stats', id, ]).data(data),
    uploadPattern: (companyId, fileId, data) => $app.api.post([ 'companies*/files*/import', companyId, fileId, ]).data(data),
    checkUploadProgress: (companyId, importId) => $app.api.get([ 'companies*/imports*', companyId, importId, ]),
    
    export: (companyId, data) => $app.api.post([ 'companies*/exports', companyId, ]).data(data),
    getExports: (companyId) => $app.api.get([ 'companies*/exports', companyId, ]),
  }
  
  departments = {
    get: (companyId, id) => $app.api.get([ 'companies*/departments*', companyId, id, ]),
    getUsers: (companyId, id) => $app.api.get([ 'companies*/departments*/users', companyId, id, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/departments', companyId, ]).data(data),
    edit: (id, data) => $app.api.patch([ 'departments*', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'departments*', id, ]),
  }
  
  users = {
    get: (id) => $app.api.get([ 'users*', id, ]),
    fireUser: (id, data) => $app.api.post([ 'users*/fire', id, ]).data(data),
    unFireUser: (id) => $app.api.post([ 'users*/un-fire', id, ]),
    edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
    deleteField: (id, data) => $app.api.patch([ 'users*/delete-field', id, ]).data(data),
    create: (companyId, data) => $app.api.post([ 'companies*/users', companyId, ]).data(data),
    getByCompany: (companyId, data) => $app.api.get([ 'companies*/users', companyId, ]).data(data),
    getByBranch: (companyId, branchId) => $app.api.get([ 'companies*/branches*/users', companyId, branchId, ]),
    getByLocationPoint: (companyId, locPointId) => $app.api.get([ 'companies*/location-points*/users', companyId, locPointId, ]),
    delete: (id) => $app.api.delete([ 'users*', id, ]),
    editPass: (id, data) => $app.api.patch([ 'users*/password', id, ]).data(data),
  }
  
  branches = {
    get: (companyId, branchId) => $app.api.get([ 'companies*/branches*', companyId, branchId, ]),
    getLocationPoints: (branchId) => $app.api.get([ 'branches*/location-points', branchId, ]),
    getPattern: (companyId, branchId) => $app.api.get([ 'companies*/branches*/download-pattern', companyId, branchId, ]),
    create: (id, data) => $app.api.post([ 'companies*/branches', id, ]).data(data),
    edit: (id, data) => $app.api.patch([ 'branches*', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'branches*', id, ]),
  }
  
  visits = {
    getStats: (companyId, data) => $app.api.post([ 'companies*/visit-stats', companyId, ]).data(data),
    getUsers: (userId) => $app.api.get([ 'users*/visits', userId, ]),
    getByBranch: (companyId, branchId) => $app.api.get([ 'companies*/branches*/visits', companyId, branchId, ]),
    getByLocationPoint: (companyId, locPointId) => $app.api.get([ 'companies*/location-points*/visits', companyId, locPointId, ]),
    create: (companyId, userId, data) => $app.api.post([ 'visits/companies*/users*', companyId, userId, ]).data(data),
    edit: (visitId, companyId, data) => $app.api.patch([ 'visits*/companies*', visitId, companyId, ]).data(data),
    delete: (visitId, companyId) => $app.api.delete([ 'visits*/companies*', visitId, companyId, ]),
  }

  checklists = {
    get: (companyId, checklistId) => $app.api.get([ 'companies*/checklists*', companyId, checklistId, ]),
    getUsers: (companyId, checklistId) => $app.api.get([ 'companies*/checklists*/users', companyId, checklistId, ]),
    remove: (checklistId) => $app.api.delete([ 'checklists', checklistId, ]),
    addUsers: (companyId, checklistId, data) => $app.api.post([ 'companies*/checklists*/add', companyId, checklistId, ]).data(data),
    removeUser: (companyId, checklistId, userId) => $app.api.post([ 'companies*/checklists*/users*/remove', companyId, checklistId, userId, ]),
    getUsersForAdd: (companyId, checklistId) => $app.api.get([ 'companies*/checklists*/users-for-add', companyId, checklistId, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/checklists', companyId, ]).data(data),
    edit: (checklistId, data) => $app.api.patch([ 'checklists*', checklistId, ]).data(data),
  }
  
  checkpoints = {
    get: (checklistId, checkpointId) => $app.api.get([ 'checklists*/checkpoints*', checklistId, checkpointId, ]),
    create: (checklistId, data) => $app.api.post([ 'checklists*/checkpoints', checklistId, ]).data(data),
    edit: (id, data) => $app.api.patch([ 'checkpoints*', id, ]).data(data),
    delete: (checkpointId) => $app.api.delete([ 'checkpoints*', checkpointId, ]),
    getVisists: (checklistId, checkpointId) => $app.api.get([ 'checklists*/checkpoints*/history', checklistId, checkpointId, ]),
    getSheet: (checklistId, checkpointId) => $app.api.get([ 'checklists*/checkpoints*/sheet', checklistId, checkpointId, ]),
  }
  
  organizations = {
    get: (companyId, id) => $app.api.get([ 'companies*/organizations*', companyId, id, ]),
    getUsers: (companyId, id) => $app.api.get([ 'companies*/organizations*/users', companyId, id, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/organizations', companyId, ]).data(data),
    edit: (id, data) => $app.api.patch([ 'organizations*', id, ]).data(data),
    remove: (id) => $app.api.delete([ 'organizations*', id, ]),
  }
  
  schedules = {
    unlink: (id, data) => $app.api.post([ 'schedules*/unlink', id, ]).data(data),
    get: (id) => $app.api.get([ 'schedules*', id, ]),
    editWorkDays: (id, data) => $app.api.patch([ 'schedules*/edit-sheet', id, ]).data(data),
    create: (data) => $app.api.post([ 'schedules', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'schedules*', id, ]).data(data),
    apply: (id, data) => $app.api.post([ 'schedules*/apply', id, ]).data(data),
    remove: (id) => $app.api.delete([ 'schedules*', id, ]),
  }
}
