<template>
  <div class="qr">
    <img :src="createQr(data, number, level)" alt="qr" />
  </div>
</template>

<script>
import qrcode from 'qrcode-generator'

export default {
  name: 'CQr',
  props: {
    data: { type: String, required: true, },
    number: { type: Number, default: 4, },
    level: { type: String, default: 'L', }, //  'L' | 'M' | 'Q' | 'H'
  },
  methods: {
    createQr(data, number, level) {
      const qr = qrcode(number, level)
      qr.addData(data)
      qr.make()
      this.$emit('created', qr.createDataURL())
      return qr.createDataURL()
    },
  },
}
</script>

<style lang="scss" scoped>
  .qr {
  
  }
</style>
