<template>
  <div class="filter" :class="{'active': isOpen}">
    <div v-if="isOpen" class="background-click" @click="close" />
    <div v-if="filterType === 'checkbox'" class="filter-box" @click.stop>
      <div class="touch-place" @click="isOpen = !isOpen">
        <div class="title">
          {{ title }}
        </div>
        <div class="icon-place" :class="{'opened': isOpen}">
          <img :src="filterArrow" alt="">
        </div>
      </div>
      <div v-show="isOpen" class="filter-window">
        <div class="wrapper">
          <div class="boxes">
            <div v-for="(item, index) in items" :key="index" class="item" @click="addBox(item)">
              <div class="box">
                <img v-if="isSelected(item)" :src="imgChecked" alt="">
              </div>
              <div class="title">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="buttons">
            <div class="submit" @click="boxDone">
              Применить
            </div>
            <div class="clean" @click="boxClean()">
              Сбросить
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="filterType === 'radio'" class="filter-box" @click.stop>
      <div class="touch-place" @click="isOpen = !isOpen">
        <div class="title">
          {{ title }}
        </div>
        <div class="icon-place" :class="{'opened': isOpen}">
          <img :src="filterArrow" alt="">
        </div>
      </div>
      <div v-show="isOpen" class="filter-window">
        <div class="wrapper">
          <div class="boxes">
            <div v-for="(item, index) in items" :key="index" class="item" @click="selectedRadio = item[returnField]">
              <div class="circle">
                <div v-show="selectedRadio === item[returnField]" class="circle-fill" />
              </div>
              <div class="title">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="buttons">
            <div class="submit" @click="done(selectedRadio)">
              Применить
            </div>
            <div class="clean" @click="radioClean">
              Сбросить
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import filterArrow from '../../assets/filter-row.svg'
import imgChecked from 'assets/done.svg'

export default {
  name: 'CFilter',
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      required: true,
    },
    returnField: {
      type: String,
      required: true,
    },
    filterType: {
      type: String,
      default: 'checkbox',
    },
  },
  data() {
    return {
      filterArrow,
      imgChecked,
      isOpen: false,
      percent: 0,
      selectedBoxes: [],
      selectedRadio: null,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    boxClean() {
      this.selectedBoxes = []
      this.$emit('clean')
      this.close()
    },
    radioClean() {
      this.selectedRadio = null
      this.$emit('clean')
      this.close()
    },
    close() {
      this.isOpen = false
      this.$emit('load')
    },
    done(value) {
      this.$emit('update:filter', value)
      this.close()
    },
    boxDone() {
      this.done(this.selectedBoxes)
    },
    isSelected(e) {
      return this.selectedBoxes.includes(e[this.returnField])
    },
    addBox(addE) {
      if (!this.selectedBoxes.includes(addE[this.returnField])) {
        this.selectedBoxes.push(addE[this.returnField])
      } else {
        this.selectedBoxes = $n.filter(this.selectedBoxes, (e) => {
          if (e !== addE[this.returnField]) {
            return e
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  padding: 0 10px;
  isolation: isolate;
  &.active {
    z-index: 1000;
  }
  .background-click {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
  }
  .filter-box {
    z-index: 100000;
    cursor: pointer;
    position: relative;
    border: 3px solid var(--primary);
    border-radius: 24px;
    min-width: 200px;
    .touch-place {
      padding: 5px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      font-size: 14px;
      font-weight: 700;
      color: var(--primary);
    }
    .icon-place {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      &.opened {
        transform: rotate(180deg);
      }
    }

    .filter-window {
      filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.3));
      background-color: #FFFFFF;
      right: -4px;
      z-index: 100000;
      top: calc(100% + 10px);
      position: absolute;
      border-radius: 12px;
      border: 3px solid var(--primary);
      min-width: 200px;
      .wrapper {
        padding: 10px;

        .buttons {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .submit {
            margin-bottom: 5px;
            cursor: pointer;
            min-width: 160px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 700;
            color: #FFFFFF;
            min-height: 30px;
            background-color: var(--primary);
            border-radius: 24px;
          }
          .clean {
            cursor: pointer;
            font-size: 12px;
            font-weight: 600;
            color: #000000;
            text-decoration: underline;
          }
        }
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .title {
          font-size: 12px;
          color: #000000;
        }
        .boxes {
          overflow: auto;
          max-height: 300px;
          .circle {
            flex-shrink: 0;
            margin-right: 8px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #D9D9D9;
            display: flex;
            align-items: center;
            justify-content: center;
            .circle-fill {
              flex-shrink: 0;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background-color: var(--primary);
            }
          }
          .box {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border: 1px solid var(--primary);
            margin-right: 8px;
            img {
              width: 90%;
              height: 90%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
</style>
