<template>
  <div class="new-checkpoint">
    <n-loader :loading="$var('load')" />
    <div class="add-form">
      <div class="title">Добавление нового чекпоинта</div>
      <div class="input-place column">
        <div class="field flex">
          <div class="title">Название чекпоинта:</div>
          <div class="field-input">
            <n-input v-bind="$form.input('title')"></n-input>
          </div>
        </div>
        <div class="field flex">
          <div class="title">День/дни:</div>
          <div class="days-boxes" :class="{'danger': $var('wDaysDanger')}">
            <div
              v-for="item in days" :key="item.index" class="day" :class="{'active': selectedDays.includes(item.index)}"
              @click="selectDay(item.index)">
              <div>
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="field flex">
          <div class="title">Временной отрезок:</div>
          <div class="field-input">
            <n-select :data="timeLines" :value.sync="timeline" :danger="$var('timelineDanger')" />
          </div>
        </div>
        <div class="field flex">
          <div class="title">Необходимое кол-во сканирований:</div>
          <div class="field-input">
            <n-input v-bind="$form.input('countToCheck')"></n-input>
          </div>
        </div>
        <div class="field flex">
          <div class="title">Достаточный радиус для сканирования, м:</div>
          <div class="field-input">
            <n-input v-bind="$form.input('radius')"></n-input>
          </div>
        </div>
        <div class="field flex">
          <div class="title">Адрес:</div>
          <div class="field-input">
            <c-address-select
              :data="addresses" v-bind="$form.input('address', 'select')" :update:value="selectAddress"
              @input="changeAddress">
            </c-address-select>
          </div>
        </div>
        <div class="map-place">
          <div id="map" style="width: 100%; height: 300px;"></div>
        </div>
      </div>
      <div class="buttons">
        <n-button class="cancel" @click="close">Отмена</n-button>
        <n-button @click="createCheckpoint()">Добавить</n-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CNewCheckpoint',
  props: {
    checklistId: {
      required: true,
    },
  },
  data() {
    return {
      days: {
        1: {
          title: 'пн',
          index: 1,
        },
        2: {
          title: 'вт',
          index: 2,
        },
        3: {
          title: 'ср',
          index: 3,
        },
        4: {
          title: 'чт',
          index: 4,
        },
        5: {
          title: 'пт',
          index: 5,
        },
        6: {
          title: 'сб',
          index: 6,
        },
        7: {
          title: 'вс',
          index: 0,
        },
      },
      selectedDays: [],
      address: null,
      addresses: [],
      map: null,
      timeline: {},
      timeLines: [
        { value: 'first', title: 'Первый день', },
        { value: 'between', title: 'Между дней', },
        { value: 'second', title: 'Второй день', },
      ],
    }
  },
  mounted() {
    ymaps.ready(this.initMap)
  },
  created() {
    this.$form.init({
      title: '',
      countToCheck: 1,
      radius: 100,
      address: {},
    })
    this.$form.rules({
      title: [ 'required', ],
    })
  },
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    createCheckpoint() {
      if (this.$form.check() && this.selectedDays.length > 0 && Object.keys(this.timeline).length > 0) {
        const data = this.$form.get()
        data.workDays = this.selectedDays
        data.lat = this.address.coords[0]+''
        data.lon= this.address.coords[1]+''
        data.address = this.address.title
        data.inDay = this.timeline.value

        this.$var('load', true)
        $api.checkpoints.create(this.checklistId, data).then(() => {
          this.close()
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        if (!this.selectedDays.length > 0) {
          this.$var('wDaysDanger', true)
        } else {
          this.$var('wDaysDanger', false)
        }
        if (!Object.keys(this.timeline).length > 0) {
          this.$var('timelineDanger', 'Поле обязательно для заполнения')
        } else {
          this.$var('timelineDanger', '')
        }
      }
    },
    selectDay(indexOf) {
      if (this.selectedDays.includes(indexOf)) {
        const dump = this.selectedDays
        this.selectedDays = []
        $n.filter(dump, (e) => {
          if (e !== indexOf) {
            this.selectedDays.push(e)
          }
        })
      } else {
        this.selectedDays.push(indexOf)
      }
    },
    initMap() {
      const lat = 43.233975
      const lon = 76.948567
      this.map = new ymaps.Map('map', {
        center: [ lat, lon, ],
        zoom: 16,
      })
      this.changeByCoords([ lat, lon, ])
      this.map.events.add('click', this.onMapClick)
    },
    selectAddress(v, coords = null) {
      if (v.obj) {
        if (!coords) {
          this.$form.set('address', v)
          this.address = v
          this.map.geoObjects.removeAll()
          this.map.geoObjects.add(v.obj)
          this.map.setBounds(v.obj.properties.get('boundedBy'), {
            checkZoomRange: true,
          })
        } else {
          this.address = {
            ...v,
            coords: coords,
          }
          this.$form.set('address', this.address)
          this.map.geoObjects.removeAll()
          const placemark = new ymaps.Placemark(coords, {})
          this.map.geoObjects.add(placemark)
        }
      }
    },
    changeByCoords(coords) {
      ymaps.geocode(coords).then((res) => {
        const firstGeoObject = res.geoObjects.get(0)
        if (firstGeoObject) {
          this.map.geoObjects.removeAll()
          const addresses = []
          res.geoObjects.each((item) => {
            addresses.push({
              value: item.properties.get('uriMetaData.URI.uri'),
              title: item.properties.get('text'),
              coords: item.geometry.getCoordinates(),
              obj: item,
            })
          })
          this.address = addresses[0]
          this.selectAddress(addresses[0], coords)
          this.addresses = addresses
        }
      })
    },
    onMapClick(event) {
      const coords = event.get('coords')
      this.changeByCoords(coords)
    },
    changeAddress(v) {
      return ymaps.geocode(v, {
        boundedBy: this.map.getBounds(),
        results: 10,
      }).then((res) => {
        const addresses = []
        res.geoObjects.each((item) => {
          addresses.push({
            value: item.properties.get('uriMetaData.URI.uri'),
            title: item.properties.get('text'),
            coords: item.geometry.getCoordinates(),
            obj: item,
          })
        })
        this.addresses = addresses
        return res
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.new-checkpoint {
  
}
</style>
