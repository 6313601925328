<template>
  <div class="c-create-role">
    <div class="form-container">
      <n-loader :loading="$var('load')" />
      <div class="title">Добавление новой роли</div>
      <div class="input-place">
        <div class="text">Название роли:</div>
        <div class="input">
          <n-input v-bind="$form.input('description')" />
        </div>
      </div>
      <div class="input-place">
        <div class="text">Выдать роль на: </div>
        <div class="radios">
          <div class="radio" @click="modelType = modelType === 'branch' ? '': 'branch'">
            <div class="title">Филиал</div>
            <div class="circle" :class="{'active': modelType === 'branch'}" />
          </div>
        </div>
      </div>
      <div v-for="(item, index) in list" :key="index" class="permits-row">
        <div class="header" :class="{'active': isMainActive(index)}">
          <div class="left">
            <div class="title">{{ headers[index] }}</div>
            <div class="switcher" :class="{'active': isMainActive(index)}" @click="selectMain(index)" />
          </div>
          <div
            class="right" :class="{'active': isMainActive(index) || open === index}"
            @click="open = open === index ? '' : index">
            ▾
          </div>
        </div>
        <div v-show="isMainActive(index) || open === index" class="drop-list" :class="{'active': isMainActive(index)}">
          <div v-for="permit in item" :key="permit?.id" class="item">
            <div class="title">{{ permit?.description }}</div>
            <div
              class="switcher" :class="{'active': isChildActive(index, permit?.name)}"
              @click="selectChild(index, permit)" />
          </div>
        </div>
      </div>
      <div class="buttons">
        <n-button class="gray" @click="$emit('update:show', false)">Отменить</n-button>
        <n-button @click="create">Сохранить</n-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CCreateRole',
  data() {
    return {
      list: {},
      selected: {},
      headers: {
        users: 'Пользователи',
        branches: 'Филиалы',
        visits: 'Посещения',
        departments: 'Отделы',
        positions: 'Должности',
        scheduleTemplates: 'Расписания',
        organizations: 'Организации',
        roles: 'Роли и доступы',
        companies: 'Компания',
      },
      modelType: '',
      open: '',
    }
  },
  created() {
    this.loadList()
    this.$form.init({
      description: '',
    })
    this.$form.rules({
      description: [ 'required', ],
    })
  },
  methods: {
    isMainActive(name) {
      return !!this.selected?.[name]
    },
    isChildActive(name, childName) {
      let is = false

      if (this.selected?.[name]) {
        $n.each(this.selected?.[name], (e) => {
          if (e.name === childName) {
            is = true
          }
        })
      }

      return is
    },
    pluckIds() {
      const ids = []
      $n.each(this.selected, (e) => {
        $n.each(e, (p) => {
          ids.push(p?.id)
        })
      })

      return ids
    },
    selectChild(name, child) {
      if (this.selected?.[name]) {
        let isExist = false

        $n.each(this.selected?.[name], (e) => {
          if (e.name === child.name) {
            isExist = true
          }
        })

        if (isExist) {
          let v = {}
          $n.each(this.selected?.[name], (e) => {
            if (e.name !== child.name) {
              v = {
                ...v,
                [e.name]: e,
              }
            }
          })
          this.selected = {
            ...this.selected,
            [name]: v,
          }
        } else {
          this.selected = {
            ...this.selected,
            [name]: {
              ...this.selected[name],
              [child.name]: child,
            },
          }
        }
      } else {
        this.selected = {
          ...this.selected,
          [name]: { [child.name]: child, },
        }
      }
    },
    selectMain(name) {
      const toAdd = this.list[name]
      if (this.selected?.[name]) {
        let v = {}
        $n.each(this.selected, (e, i) => {
          if (i !== name) {
            v = {
              ...v,
              [i]: e,
            }
          }
        })
        this.selected = v
      } else {
        this.selected = {
          ...this.selected,
          [name]: toAdd,
        }
      }
    },
    loadList() {
      this.$var('load', true)
      $api.accesses.getPermits().all().then((response) => {
        this.list = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    create() {
      if (this.$form.check()) {
        const data = {
          description: this.$form.get('description'),
          permitsIds: this.pluckIds(),
        }
        if (this.modelType.length > 0 ) {
          data.modelType = this.modelType
        }
        this.$var('load', true)
        $api.accesses.createRole(data).then(() => {
          this.$emit('created')
          this.selected = {}
          this.$form.reset()
          this.$emit('update:show', false)
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-create-role {
  margin-top: 20px;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep {
      .gray {
        background-color: #E9E9E9;
        border-color: #E9E9E9;
        color: #000000;
      }
    }
  }

  .switcher {
    flex-shrink: 0;
    width: 60px;
    height: 30px;
    background-color: #E9E9E9;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid #E9E9E9;

    &:before {
      content: '';
      display: block;
      width: 28px;
      height: 28px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.3s ease;
    }

    &.active {
      background-color: var(--primary);
    }

    &.active::before {
      transform: translateX(30px);
    }
  }


  .input-place {
    display: flex;
    margin-top: 15px;
    align-items: center;

    .text {
      margin-right: 15px;
    }

    .input {
      flex: 2;
      max-width: 400px;
    }
  }

  ::v-deep {
    .n-button {
      margin-top: 20px;
      min-width: 240px;
      color: #FFFFFF;
      border: 3px solid var(--primary);
      border-radius: 24px;
      background-color: var(--primary);
    }
  }


  .permits-row {
    margin-top: 15px;
    .header {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #E9E9E9;
      justify-content: space-between;

      &.active {
        border: none;
      }

      .left {
        display: flex;
        align-items: center;

        .title {
          font-size: 16px;
          margin-right: 15px;
        }
      }

      .right {
        cursor: pointer;
        font-size: 23px;
        transition: 500ms;

        &.active {
          transform: rotate(180deg);
        }
      }
    }

    .drop-list {
      display: flex;
      align-items: center;
      transition: 500ms;

      &.active {
        border-bottom: 1px solid #E9E9E9;
      }

      .switcher {
        width: 40px;
        height: 20px;

        &:before {
          width: 18px;
          height: 18px;
        }

        &.active::before {
          transform: translateX(20px);
        }
      }

      .item {
        margin-right: 15px;
        padding: 10px;
        display: flex;
        align-items: center;

        .title {
          margin-right: 10px;
          font-size: 12px;
        }
      }
    }
  }

  .form-container {
    .title {
      text-align: center;
      font-size: 18px;
    }
  }

  .radio {
    cursor: pointer;
    display: flex;
    align-items: center;
    .title {
      font-size: 16px;
      margin-right: 15px;
    }
    .circle {
      background-color: #E9E9E9;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      transition: 0.3s;
      &.active {
        background-color: var(--primary);
      }
    }
  }
}
</style>
