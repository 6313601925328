<template>
  <div class="n-select" :class="{'small': small}">
    <label v-if="title">{{ title }}</label>
    <v-select
      v-if="!text" v-model="s_value" :multiple="Array.isArray(value)" :searchable="!readonly"
      :options="data" :label="label" @input="update()" />
    <div v-else class="text-place">
      <div v-if="!Array.isArray(value)">{{ value[label] }}</div>
      <div v-else>
        <span v-for="(v, i) in value" :key="i">{{ v[label] + (value.length > 1 ? (i < value.length-1 ? ', ' : '') : '') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'

export default {
  name: 'NSelect',
  props: {
    value: { default: '', },
    text: { default: false, type: Boolean, },
    readonly: { default: false, type: Boolean, },
    title: { type: String, default: '', },
    placeholder: { type: String, default: '', },
    data: { type: Array, default: () => [], },
    label: { type: String, default: 'title', },
    small: { type: Boolean, default: false, },
    'update:value': { type: Function, default: () => {}, },
  },
  watch: {
    value() {
      this.s_value = this.value
    },
  },
  data() {
    return {
      s_value: this.value,
    }
  },
  methods: {
    update() {
      this.$emit('change', this.s_value)
      this['update:value'](this.s_value)
      this.$emit('update:value', this.s_value)
      this.$emit('select', this.s_value)
    },
  },
}
</script>

<style lang="scss" scoped>

.n-select {

  min-width: 220px;
  &.small {
    min-width: 20px;
    :deep() {
      .vs__actions {
        display: none;
      }
      .vs__dropdown-menu {
        min-width: 0;
      }
    }
  }
  :deep(.vs__clear) {
      display: none;
  }
  :deep() {
    .vs__selected {
      background-color: #FFFFFF;
    }
    .vs__dropdown-option {
      white-space: normal !important;
    }
    .vs__dropdown-menu {
      min-width: 100px;
    }
  }
  :deep(.vs__dropdown-toggle) {
    border: 1px solid var(--border-color);
  }
  label {
    display: block;
    color: inherit;
    width: 100%;
    font-size: 1em;
    line-height: 1.2;
    text-align: left;
    padding: 6px 0;
  }

    .text-place {
      background-color: #FFFFFF;
      border-radius: var(--border-radius);
      padding: 9px 15px;
    }
    .v-select {
      background-color: var(--default);
      border-radius: var(--border-radius);

      .vs__dropdown-toggle {
        border: 1px solid var(--border-color);
        padding: 8px 8px 9px;
      }
      .vs__dropdown-menu {
        background-color: var(--default);
      }
    }
    label {
      font-size: 1.1em;
      font-weight: bold;
      padding-bottom: 12px;

    }
}
</style>
