import Vue from 'vue'
import NastCore from 'nast'
import settings from './_config'
import libs from './_libs'
import ui from './_ui'
import components from 'components/Index'


Vue.mixin({
  components,
})
Vue.use(ui)
NastCore.createApp(settings, libs)
