<template>
  <div class="c-edit-visit" @click="close">
    <n-loader :loading="$var('load')" />
    <div class="form" @click.stop>
      <div class="title main">Редактирование посещения</div>

      <div class="time-selector-wrapper">
        <div class="title">
          Время посещения:
        </div>
        <div class="time-selector">
          <n-select name="inputdisabled" small :data="hours" v-bind="$form.input('hour', 'select')" readonly class="time-select" />
          :<n-select name="inputdisabled2" small :data="minutes" v-bind="$form.input('minute', 'select')" readonly class="time-select" />
        </div>
      </div>
      <div class="buttons">
        <n-button class="cancel" @click="close">Отмена</n-button>
        <n-button @click="add">Сохранить</n-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CAddVisit',
  props: {
    visit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hours: [],
      minutes: [],
    }
  },
  created() {
    this.$form.init({
      hour: {},
      minute: {},
    })
    this.generateHours()
    this.findTime()
  },
  methods: {
    findTime() {
      const hour = $app.date.format(this.visit.createdAt, 'hours')
      const minute = $app.date.format(this.visit.createdAt, 'minutes')
      this.$form.set('hour', $n.find(this.hours, (e) => {
        if (Number(e.value) === Number(hour)) {
          return e
        }
      }))
      this.$form.set('minute', $n.find(this.minutes, (e) => {
        if (Number(e.value) === Number(minute)) {
          return e
        }
      }))
    },
    close() {
      this.$emit('update:show', false)
    },
    generateHours() {
      this.hours = Array.from({ length: 24, }, (_, i) => ({
        value: i > 9?i:'0'+(i),
        title: i > 9?i:'0'+(i),
      }))
      this.minutes = Array.from({ length: 60, }, (_, i) => ({
        value: i > 9?i:'0'+(i),
        title: i > 9?i:'0'+(i),
      }))
    },
    add() {
      this.$var('load', true)
      const data = {
        date: new Date(new Date(this.visit.createdAt).setHours(this.$form.get('hour.value'), this.$form.get('minute.value'), 0, 0)),
        year: new Date(this.visit.createdAt).getFullYear()+'',
      }
      $api.visits.edit(this.visit.id, $app.auth.user().companyId, data).then(() => {
        this.close()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-visit {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #00000080;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;


  :deep() {
    .n-select {
      .vs__actions {
        display: none;
      }
    }
  }


  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .n-button {
      border: none;
      border-radius: 24px;
      background-color: var(--primary) !important;
      color: #FFFFFF;
      &.cancel {
        color: #000000;
        background-color: #D4CFCF !important;
      }
    }
  }
  .buttons {
    width: 100%;
  }
  .form {
    padding: 15px;
    z-index: 1001;
    min-width: 400px;
    min-height: 200px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: stretch;
    .title {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
      &.main {
        margin-bottom: 15px;
      }
    }
    .time-selector-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .title {
        margin-right: 15px;
        font-weight: normal;
        font-size: 14px;
        color: #000000;
      }
    }
    .time-selector {
      display: flex;
      align-items: center;
      justify-content: start;
      ::v-deep {

        .n-select {
          .n-icon {
            display: none;
          }
          .n-text {
            text-align: center;
          }
          &:first-child {
            margin-right: 5px;
          }
          &:last-child {
            margin-left: 5px;
          }
          width: 60px;
          .n-content {
            padding: 0;
          }
          .n-icon {
            margin: 0 !important;
          }
          .n-popup-content {
            width: 60px;
            .n-dropdown-item {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .n-item {
              text-align: center;
            }
          }
          .n-wrapper {
            padding: 0 5px;
            width: 60px;
          }
          .n-dropdown {
            width: 60px;
          }
        }
      }
    }
  }
}
</style>
