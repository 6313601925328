<template>
  <div class="c-attach-role" @click.stop="close">
    <div :class="{'hidden': !user.accesses?.[0]}">
      <n-button v-if="user.accesses?.[0]" class="apply-btn detach" @click.stop="detachAccess">Отозвать</n-button>
    </div>
    <n-button v-if="!user.accesses?.[0]" class="apply-btn" @click.stop="show = true">Назначить</n-button>

    <div v-if="show" class="c-add-visit">
      <n-loader :loading="$var('load')" />
      <div class="form" @click.stop>
        <div class="title main">Выберите роль</div>
        <n-select label="description" :data="roles" v-bind="$form.input('role', 'select')" />
        <div v-if="showBranch" class="title main">Выберите филиал</div>
        <n-select v-if="showBranch" :data="branches" item-value="id" v-bind="$form.input('branch', 'select')" />
        <div class="buttons">
          <n-button class="cancel" @click="close">Отмена</n-button>
          <n-button @click="apply">Применить</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import imgDone from '../../assets/done.svg'

export default {
  name: 'CAttachRole',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgDone,
      show: false,
      roles: [],
      branches: [],
    }
  },
  computed: {
    showBranch() {
      const res = this.$form.get('role.modelType') === 'branch'
      if (res) {
        this.$form.rules({
          branch: [ 'required', ],
          role: [ 'required', ],
        })
      }
      
      return res
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.load()
        this.loadBranches()
        this.$form.reset()
      }
    },
  },
  created() {
    this.$form.init({
      role: {},
      branch: [],
    })
    this.$form.rules({
      role: [ 'required', ],
    })
  },
  methods: {
    close() {
      this.show = false
      this.$emit('closed')
    },
    detachAccess() {
      this.$var('load', true)
      $api.accesses.detachAccess(this.user.accesses[0].id).then(() => {
        this.close()
      }).finally(() => {
        this.$var('load', true)
      })
    },
    pluckIds() {
      return $n.map(this.$form.get('branch'), (e) => {
        return e.id
      })
    },
    apply() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          name: this.$form.get('role.name'),
          userId: this.user.id,
        }
        if (this.$form.get('role.modelType') === 'branch') {
          data.modelId = this.pluckIds()
          data.modelType = 'branch'
        }
        $api.accesses.createAccess(data).then(() => {
          this.close()
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    load() {
      this.$var('load', true)
      $api.accesses.getRoles().all().then((response) => {
        this.roles = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadBranches() {
      this.$var('load', true)
      $api.branches.get($app.auth.user().companyId).then((response) => {
        this.branches = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-attach-role {

  .n-select {
    width: 100%;
  }


  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  .apply-btn {
    width: 90px;
    min-height: 30px;
    ::v-deep {
    }
  }
  .n-select {
    margin: 15px;
  }
  .pinned-schedule {
    height: 40px;
    background-color: var(--primary);
    color: #ffffff;
    border-radius: 24px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  .pinned {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.hidden {
      opacity: 0  ;
    }
  }

  .field {
    flex: 1;
    display: flex;
    margin-bottom: 15px;
    .n-input {
      margin-left: 25px;
      min-width: 100px;
    }
    &.check-box {
      display: flex;
      align-items: center;
      width: 100%;
      .box {
        margin-right: 15px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #000000;
      }
      .title {
        justify-content: left;
        margin-bottom: 0;
      }
    }
  }
  .schedule-list {
    width: 100%;
    max-height: 400px;
    overflow: auto;
    border-top: 2px solid #EBEBEB;
    border-bottom: 2px solid #EBEBEB;
    padding: 5px;
    margin-bottom: 15px;
    .schedule-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;
      background-color: rgba(235, 235, 235, 0.38);
      &:last-child {
        margin-bottom: 0;
      }
      .circle {
        background-color: #EBEBEB;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        &.active {
          background-color: var(--primary);
        }
      }
    }
  }

  .c-add-visit {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #00000080;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .n-button {
    border: none;
    border-radius: 24px;
    background-color: var(--primary);
    color: #FFFFFF;
    &.detach {
      background-color: red;
    }
    &.cancel {
      color: #000000;
      background-color: #D4CFCF !important;
    }
  }
  .form {
    padding: 15px;
    z-index: 1001;
    min-width: 400px;
    min-height: 200px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: stretch;
    .title {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
      &.main {
        margin-bottom: 15px;
      }
    }
    .time-selector-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .title {
        &.danger {
          width: 100%;
          color: red;
          text-align: center;
        }
        margin-right: 15px;
        font-weight: normal;
        font-size: 14px;
        color: #000000;
      }
    }
    .time-selector {
      display: flex;
      align-items: center;
      justify-content: start;
      ::v-deep {
        .n-select {
          .n-icon {
            display: none;
          }
          .n-text {
            text-align: center;
          }
          &:first-child {
            margin-right: 5px;
          }
          &:last-child {
            margin-left: 5px;
          }
          width: 60px;
          .n-content {
            padding: 0;
          }
          .n-icon {
            margin: 0 !important;
          }
          .n-popup-content {
            width: 60px;
            .n-dropdown-item {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .n-item {
              text-align: center;
            }
          }
          .n-wrapper {
            padding: 0 5px;
            width: 60px;
          }
          .n-dropdown {
            width: 60px;
          }
        }
      }
    }
  }
}
</style>
