import BaseAuth from 'nast-auth'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import size from 'lodash/size'

/**
 *
 */
export default class NastAuth extends BaseAuth {
  /**
     * @param {string|object|array} name
     * @param {any} fieldValue  Если передано null,
     * @param {string} fieldName
     * @param {boolean} any
     * @param {array} grants
     * @return {boolean}
     *
     */
  can(name, fieldValue = undefined, fieldName = 'id', any = false, grants = undefined) {
    if (isArray(name)) {
      grants = fieldValue
      for (const i in name) {
        if (!this.can(name[i], grants)) {
          return false
        }
      }
      return true
    }

    if (isObject(name)) {
      grants = fieldValue
      fieldValue = name.value
      fieldName = name.field || 'id'
      any = name.any
      name = name.name
    }

    if (!grants) {
      grants = this.grants()
      if (!grants) {
        return false
      }
    }

    const grant = grants[name]
    if (grant === undefined) {
      return false
    }
    if (size(grant) === 0 || any === true) {
      return true
    }

    return true
  }
}
