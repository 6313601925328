<template>
  <div class="c-appbar">
    <div class="backgorund" :style="{background: setBg()+''}">
      <div v-if="open" class="background-clicker" @click="open = !open" />
      <div class="bar-container" @click.stop>
        <div class="top">
          <div class="logo-edit">
            <div v-if="user.company?.logo?.length === 0" class="logo" @click="$router.push({name:'profile'})">
              <img :src="imgNoLogo" alt="">
            </div>
            <div v-else class="logo">
              <img :src="user.company?.logo" alt="">
            </div>
          </div>
        </div>
        <div class="bottom">
          <n-link class="link" :to="{name: 'stats'}" :class="{'active': isActive('stats')}">Статистика</n-link>
          <n-link class="link" :to="{name: 'timesheet'}" :class="{'active': isActive('timesheet')}">Табель</n-link>
          <n-link
            v-if="$app.auth.can('branches_get') || $app.auth.can('branches_getAll')"
            class="link" :to="{name: 'index'}" :class="{'active': isActive('index')}">
            Филиалы
          </n-link>
          <n-link
            v-if="$app.auth.can('branches_get') || $app.auth.can('branches_getAll')"
            class="link" :to="{name: 'locationPoints'}" :class="{'active': isActive('locationPoints')}">
            Точки контроля
          </n-link>
          <n-link
            v-if="$app.auth.can('departments_get') || $app.auth.can('departments_get')"
            class="link" :to="{name: 'departments'}" :class="{'active': isActive('departments')}">
            Отделы
          </n-link>
          <n-link
            v-if="user.company?.successCard === 'bazis' && ($app.auth.can('organizations_get') || $app.auth.can('organizations_get'))"
            class="link" :to="{name: 'organizations'}"
            :class="{'active': isActive('organizations')}">
            Организации
          </n-link>
          <n-link
            v-if="$app.auth.can('users_get') || $app.auth.can('users_get')"
            class="link" :to="{name: 'users'}" :class="{'active': isActive('users')}">
            Сотрудники
          </n-link>
          <n-link
            v-if="$app.auth.can('scheduleTemplates_get')" class="link" :to="{name: 'scheduleTemplates'}"
            :class="{'active': isActive('scheduleTemplates')}">
            Расписания
          </n-link>
          <n-link
            v-if="$app.auth.can('positions_get')" class="link" :to="{name: 'positions'}"
            :class="{'active': isActive('positions')}">
            Должности
          </n-link>
          <!--          <n-link class="link" :to="{name: 'checklists'}" :class="{'active': isActive('checklists')}">Чек-листы</n-link>-->
        </div>
        <div class="info-align" @click.stop>
          <div class="user-info">
            <div class="info-wrapper">
              <div class="logout-button">
                <div class="open-button" @click="open = !open">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_42_393)">
                      <path d="M20 40C8.954 40 0 31.046 0 20C0 8.954 8.954 0 20 0C31.046 0 40 8.954 40 20C40 31.046 31.046 40 20 40ZM10.026 32.512C12.8571 34.7754 16.3754 36.0058 20 36C23.94 36 27.546 34.576 30.334 32.216C29.0314 30.8795 27.4741 29.8178 25.7541 29.0936C24.034 28.3695 22.1863 27.9976 20.32 28C18.3852 27.9978 16.471 28.3977 14.6989 29.1744C12.9269 29.9512 11.3356 31.0877 10.026 32.512ZM7.232 29.64C8.91291 27.8561 10.9413 26.4354 13.1923 25.4654C15.4432 24.4954 17.869 23.9967 20.32 24C22.6833 23.9969 25.0239 24.4607 27.2075 25.3648C29.391 26.2688 31.3745 27.5953 33.044 29.268C34.7562 26.8582 35.7673 24.0213 35.9651 21.0718C36.163 18.1224 35.5399 15.1758 34.165 12.5589C32.79 9.94205 30.717 7.75725 28.1759 6.24689C25.6348 4.73653 22.725 3.95968 19.7692 4.0025C16.8134 4.04533 13.9273 4.90617 11.431 6.48951C8.93476 8.07286 6.92592 10.3168 5.62738 12.9724C4.32885 15.628 3.79139 18.5914 4.07463 21.5339C4.35787 24.4764 5.45075 27.2829 7.232 29.642V29.64ZM20 22C17.8783 22 15.8434 21.1571 14.3431 19.6569C12.8429 18.1566 12 16.1217 12 14C12 11.8783 12.8429 9.84344 14.3431 8.34315C15.8434 6.84285 17.8783 6 20 6C22.1217 6 24.1566 6.84285 25.6569 8.34315C27.1571 9.84344 28 11.8783 28 14C28 16.1217 27.1571 18.1566 25.6569 19.6569C24.1566 21.1571 22.1217 22 20 22ZM20 18C21.0609 18 22.0783 17.5786 22.8284 16.8284C23.5786 16.0783 24 15.0609 24 14C24 12.9391 23.5786 11.9217 22.8284 11.1716C22.0783 10.4214 21.0609 10 20 10C18.9391 10 17.9217 10.4214 17.1716 11.1716C16.4214 11.9217 16 12.9391 16 14C16 15.0609 16.4214 16.0783 17.1716 16.8284C17.9217 17.5786 18.9391 18 20 18Z" fill="#2C117C" />
                    </g>
                    <defs>
                      <clipPath id="clip0_42_393">
                        <rect width="40" height="40" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div v-if="open" class="logout-wrapper" @click.stop>
              <div class="buttons">
                <div
                  v-if="$app.auth.can('companies_edit')||$app.auth.can('roles_create')"
                  class="button " @click="() => {
                    $router.push({name: 'profile'})
                    open = false
                  }">
                  <div class="icon-place">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 0C14.1576 0 15.7473 0.65848 16.9194 1.83058C18.0915 3.00268 18.75 4.5924 18.75 6.25C18.75 7.9076 18.0915 9.49732 16.9194 10.6694C15.7473 11.8415 14.1576 12.5 12.5 12.5C10.8424 12.5 9.25268 11.8415 8.08058 10.6694C6.90848 9.49732 6.25 7.9076 6.25 6.25C6.25 4.5924 6.90848 3.00268 8.08058 1.83058C9.25268 0.65848 10.8424 0 12.5 0ZM12.5 15.625C19.4063 15.625 25 18.4219 25 21.875V25H0V21.875C0 18.4219 5.59375 15.625 12.5 15.625Z" fill="#2C117C" />
                    </svg>
                  </div>
                  <div class="title">
                    Профиль компании
                  </div>
                </div>
                <div class="button" @click="$app.auth.logout()">
                  <div class="icon-place">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.94737 20H6.57895V22.5H22.3684V2.5H6.57895V5H3.94737V1.25C3.94737 0.918479 4.086 0.600537 4.33275 0.366116C4.57951 0.131696 4.91419 0 5.26316 0H23.6842C24.0332 0 24.3679 0.131696 24.6146 0.366116C24.8614 0.600537 25 0.918479 25 1.25V23.75C25 24.0815 24.8614 24.3995 24.6146 24.6339C24.3679 24.8683 24.0332 25 23.6842 25H5.26316C4.91419 25 4.57951 24.8683 4.33275 24.6339C4.086 24.3995 3.94737 24.0815 3.94737 23.75V20ZM6.57895 11.25H15.7895V13.75H6.57895V17.5L0 12.5L6.57895 7.5V11.25Z" fill="#2C117C" />
                    </svg>
                  </div>
                  <div class="title">
                    Выход
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgProfile from 'assets/icon-proifle.svg'
import imgNoLogo from 'assets/no-logo-icon.svg'
import imgToProfile from 'assets/icon-profle-button.svg'
import imgLogout from 'assets/icon-logout.svg'

export default {
  name: 'CAppbar',
  data() {
    return {
      imgProfile,
      imgToProfile,
      imgLogout,
      open: false,
      user: {},
      imgNoLogo,
    }
  },
  computed: {
    route() {
      return this.$route.name
    },
  },
  created() {
    this.getCompany()
  },
  methods: {
    isActive(name) {
      if (name === 'index' ) {
        return [ 'index', 'branch', ].includes(this.route)
      }
      if (name === 'locationPoints' ) {
        return [ 'locationPoints', 'locationPoint', ].includes(this.route)
      }
      if (name === 'positions' ) {
        return [ 'position', 'positions', ].includes(this.route)
      }
      if (name === 'scheduleTemplates' ) {
        return [ 'scheduleTemplates', 'scheduleTemplate', ].includes(this.route)
      }
      if (name === 'departments') {
        return [ 'departments', 'department', ].includes(this.route)
      }
      if (name === 'timesheet') {
        return this.route === 'timesheet'
      }
      if (name === 'users') {
        return [ 'users', 'user', ].includes(this.route)
      }
      if (name === 'stats') {
        return this.route === 'stats'
      }
      if (name === 'checklists') {
        return [ 'checklists', 'checklist', ].includes(this.route)
      }
      if (name === 'organizations') {
        return [ 'organizations', 'organization', ].includes(this.route)
      }
    },
    setBg() {
      return 'linear-gradient(180deg, '+ (localStorage.getItem('colorShadow') || '#57419D') +' 20%, rgba(255, 226, 254, 0) 100%)'
    },
    getCompany() {
      this.$var('load', true)
      const query = 'with[company][with][settings]'
      $api.auth.info().query(query).then((response) => {
        this.user = response.data.content.user
        $app.auth.grants(response.data.content.grants)
        $app.auth.user(this.user)
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-appbar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  background: #FFFFFF;
  .background-clicker {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
  }
  .bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .backgorund {
    padding: 30px 50px;
    height: 108px;
    background: linear-gradient(180deg, var(--primary) 20%, rgba(255, 226, 254, 0) 100%);

  }

  .logout-wrapper {
    position: absolute;
    left: -240px;
    z-index: 100001;
    right: 0;
    top: calc(100% + 10px);
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 4px 4px 0 #00000040;
    .buttons {
      width: 100%;
      .button {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        padding: 10px;

        &:hover {
          background-color: #EBEBEB;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .icon-place {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          svg {
            path {
              fill: var(--primary)
            }
          }
        }
        .title {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .top {
    margin-right: 20px;
    display: flex;
    .logo-edit {
      display: flex;
      align-items: center;
      .logo {
        height: 40px;
        width: 200px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

  }

  .info-align {
    .user-info {
      border-radius: 26px;
      position: relative;

      .info-wrapper {
        width: 100%;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .open-button {
          svg {
            path {
              fill: var(--primary);
            }
          }
        }
        &.logout {
          margin-top: 10px;
          justify-content: center;
        }
      }

      .info {
        z-index: 10000001;
        color: #767676;
        font-size: 0.8em;
        margin-right: 10px;

        div:first-child {
          font-size: 1.2em;
          color: #000000;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .link {
      margin-right: 20px;
      margin-bottom: 5px;
      color: #000000;
      cursor: pointer;
      flex-shrink: 0;
      min-width: 125px;
      padding: 5px;
      min-height: 40px;
      font-size: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid var(--primary);
      border-radius: 24px;
      background-color: #FFFFFF;
      &.active {
        color: #ffffff;
        background-color: var(--primary);
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
